import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import { whitelabel_id } from "../../constants/ids"
import apiCall from "../../utils/apiCall"

const BusinessPlans = () => {
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const [loading, setLoading] = useState(false)
    const [userPlan, setUserPlan] = useState({})
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')
        }
        getTransactionDetails()
    }, [])

    const getTransactionDetails = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,

        }

        const url = API_ENDPOINTS.GET_SELECTED_PLAN
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setUserPlan(response?.userplan)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch user plan')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to fetch user plan')
            setLoading(false)
        }
    }
    return (
        <>
            <div className="d-flex justify-content-start mb-4">
                <h3> Business Plans</h3>
            </div>
            <div className="row">
                {!loading ? <div className="col-12 col-md-6 col-lg-4">
                    {userPlan ? <div className="card">
                        <div class="card-body">
                            <h3>{userPlan?.plan_name}</h3>
                            <div>
                                Opening Account  <strong>${userPlan?.cost_opening_account}</strong> 
                            </div>
                            <div className="mt-3">
                                <strong>${userPlan?.monthly_account}</strong> per month
                            </div>
                            <div className="mt-4 mx-3 mb-4">
                                <ul class="unorder-list">
                                  <li>SEPA SCT In Fee €{userPlan?.sepa_in_fee} + {userPlan?.sepa_in_fee_percentage}%</li>
									<li>SEPA SCT Out Fee €{userPlan?.sepa_out_fee}+ {userPlan?.sepa_out_fee_percentage}%</li>
									<li>SEPA Instant Out Fee  €{userPlan?.sepa_instant_out_fee}+ {userPlan?.sepa_instant_out_fee_percentage}%</li>
									<li>SEPA Instant In Fee €{userPlan?.sepa_instant_in_fee}+ {userPlan?.sepa_instant_in_fee_percentage}%</li>
                                </ul>
                            </div>
                        </div>
                    </div> : 'No Data found'}
                </div> :
                    <div className="text-center ">
                        <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {/* <div className="col-12 col-md-6 col-lg-4">
                    <div className="card">
                        <div class="card-body">
                            <h3>Silver</h3>
                            <div>
                                <strong>Opening Account $0.00</strong> per month
                            </div>
                            <div className="mt-3">
                                <strong>$5.00</strong> per month
                            </div>
                            <div className="mt-4 mx-3 mb-4">
                                <ul class="unorder-list">
                                    <li>SEPA SCT In Fee Free</li>
                                    <li>SEPA SCT Out Fee $1.00</li>
                                    <li>SEPA Instant Out Fee $2.00</li>
                                    <li>SEPA Instant In Fee Free</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="card">
                        <div class="card-body">
                            <h3>Silver</h3>
                            <div>
                                <strong>Opening Account $0.00</strong> per month
                            </div>
                            <div className="mt-3">
                                <strong>$5.00</strong> per month
                            </div>
                            <div className="mt-4 mx-3 mb-4">
                                <ul class="unorder-list">
                                    <li>SEPA SCT In Fee Free</li>
                                    <li>SEPA SCT Out Fee $1.00</li>
                                    <li>SEPA Instant Out Fee $2.00</li>
                                    <li>SEPA Instant In Fee Free</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </>
    )
}
export default BusinessPlans