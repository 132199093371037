import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import apiCall from "../../utils/apiCall"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import { whitelabel_id } from "../../constants/ids"
// import { Pagination } from "antd"

const POSTransactionsHistory = () => {
    const $ = window.jQuery

    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const startDateRef = useRef()
    const endDateRef = useRef()
    const merchantRef = useRef()
    const cityRef = useRef()
    const trxRef = useRef()
    const trxTypeRef = useRef()
    const [errors, setErrors] = useState({})
    const [transactions, setTransactions] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState(false)
    const [trxDetails, setTrxDetails] = useState(null)
    const closeRef = useRef()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [descriptions, setDescriptions] = useState([])
    const [pagination, setPagination] = useState({
        num_rows: 0,
        page_number: 0,
        per_page: 0
    })
    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')

        }
        getTransactions()
    }, [])
    useEffect(() => {
        // Get current date
        const currentDate = new Date();

        // Format date as 'YYYY-MM-DD'
        const formattedDate = currentDate.toISOString().split('T')[0];

        // Set formatted date as the placeholder
        endDateRef.current.placeholder = formattedDate;
        startDateRef.current.placeholder = formattedDate;
    }, []);
    const resetTransactions = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,

        }

        const url = API_ENDPOINTS.GET_POS_TRANSACTIONS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.trx) {
            setLoading(false)
            setSearch(null)
            startDateRef.current.value = '';
            endDateRef.current.value = ''
            merchantRef.current.value = '0'
            $(merchantRef.current).val('').trigger('change')
            $(cityRef.current).val('').trigger('change')
            $(trxRef.current).val('').trigger('change')
            
            setTransactions(response?.trx)
            setDescriptions(response?.decresult_array)
            setPagination({
                num_rows: response?.num_rows,
                page_number: 1,
                per_page: response?.per_page
            })
        } else if (response?.status == 0) {
            setLoading(false)
            console.error(response?.message || 'unable to fetch transactions')
        } else {
            setLoading(false)
            console.error(response?.error || 'unable to fetch transactions')
        }
    }
    const getTransactions = async (page = 1) => {
        setLoading(true)
        const { web_token, user_id } = authUser
        let body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        if (startDateRef.current.value) {
            body.startdate = startDateRef.current.value
        }
        if (endDateRef.current.value) {
            body.enddate = endDateRef.current.value
        }
        if (page) {
            body.page = page
        }
        if (merchantRef.current.value) {
            body.description_1 = merchantRef.current.value
        }
        if (cityRef.current.value) {
            body.description_2 = cityRef.current.value
        }
        if (trxRef.current.value) {
            body.status = trxRef.current.value
        }
        const url = API_ENDPOINTS.GET_POS_TRANSACTIONS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.trx) {
            setTransactions(response?.trx)
            setDescriptions(response?.decresult_array)
            setPagination({
                num_rows: response?.num_rows,
                page_number: response?.page_number,
                per_page: response?.per_page
            })
            setLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch transactions')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to fetch transactions')
            setLoading(false)
        }
    }



    const getTransactionDetails = async (id) => {
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            unique_id: id
        }

        const url = API_ENDPOINTS.TRANSACTION_DETAILS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setTrxDetails(response?.trx)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch transactions')
        } else {
            console.error(response?.error || 'unable to fetch transactions')
        }
    }
    const navigateToDetails = () => {
        closeRef.current.click();
        navigate('/transactions-details')
    }
    return (
        <>
            <div className="d-flex justify-content-start mb-4">
                <h3>POS Transactions History</h3>

            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div class="mt-5 mx-5">
                        <div class="row">
                            <div class="col-xl-2 col-md-4 col-lg-4 mb-md-1 mb-1">
                                <span className="text-muted">Select Merchant</span>
                                <select ref={merchantRef} class="default-placeholder form-control" placeholder="Select Merchant">
                                    <option disabled selected value={""}>Select Merchant</option>
                                    {descriptions?.length > 0 && descriptions?.map((d) => {
                                        return <option value={d.description_1}>{d.description_1}</option>
                                    })}
                                </select>
                            </div>
                            <div class="col-xl-2 col-md-4 col-lg-4 mb-md-1 mb-1">
                                <span className="text-muted">Select City</span>
                                <select ref={cityRef} class="default-placeholder form-control" placeholder="Select City">
                                    <option disabled selected value={""}>Select City</option>
                                    {descriptions?.length > 0 && descriptions?.map((d) => {
                                        return <option value={d.description_2}>{d.description_2}</option>
                                    })}

                                </select>

                            </div>
                            <div class="col-xl-2 col-md-4 col-lg-4 mb-md-1 mb-1">
                                <span className="text-muted">Select TRX type</span>
                                <select ref={trxRef} className="default-placeholder form-control " placeholder="Select TRX Type">
                                    <option disabled selected value={0}>Select TRX type</option>
                                    <option value={3}>Paid</option>
                                    <option value={2}>Partially-Paid</option>
                                    <option value={1}>Unpaid</option>
                                </select>

                            </div>
                            <div class="col-xl-2 col-md-4 col-lg-4 mb-md-0 mb-lg-0 mb-1">
                                <div class="form-material">
                                    <span className="text-muted">select start date</span>
                                    <input type="text" ref={startDateRef} class="form-control" placeholder="2017-06-04" id="mdate" onChange={(e) => { console.log(e) }} />
                                    {/* {errors?.startDate && */}
                                    {/* } */}
                                </div>
                            </div>


                            <div class="col-xl-2 col-md-4 col-lg-4 mb-md-0">
                                <div class="form-material">
                                    <span className="text-muted">select end date</span>
                                    <input value={endDateRef.current?.value || ''} type="text" ref={endDateRef} class="form-control" placeholder="2017-06-04" id="mdate2" onChange={(e) => { console.log(e) }} />
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 mt-3 mt-md-0 d-flex">
                                <button disabled={loading} onClick={() => { getTransactions(); setFilteredTransactions(true) }} type="button" class="btn btn-success me-2 mt-auto mb-1" style={{ maxHeight: '70px' }}>Search</button>
                                {filteredTransactions && <button onClick={() => { resetTransactions(); setFilteredTransactions(false) }} type="button" class="btn btn-dark mt-auto mb-1" style={{ maxHeight: '70px' }}>Reset</button>}
                            </div>


                        </div>
                    </div>
                    {!loading ? <div className="card-body">
                        {transactions?.length >= 0 ? <div className="table-responsive">
                            <table className="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>LEGAL NAME</strong>
                                        </th>

                                       
                                        <th>
                                            <strong>AUTHCODE</strong>
                                        </th>
                                        <th>
                                            <strong>RAWAMT</strong>
                                        </th>
										<th>
                                            <strong>Commission</strong>
                                        </th>
										<th style={{ whiteSpace: 'wrap' }}>
                                            <strong>Non-standard Cards</strong>
                                        </th>
										 <th>
                                            <strong>Trx STATUS</strong>
                                        </th>
                                        <th>
                                            <strong>Paid STATUS</strong>
                                        </th>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>PAID DATE</strong>
                                        </th>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>TRX DATE</strong>
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions?.map((trx) => {
                                        return <tr>
                                            <td><strong>{trx?.legal_name}</strong></td>
                                           
                                            <td>
                                                {trx?.authorization_code}
                                            </td>
                                            <td>€{trx?.raw_amount}</td>
                                            <td>€{trx?.commission}</td>
                                            <td>€{trx?.nscc}</td>
                                            <td>{trx?.service_msg}</td>
                                            <td>{trx?.nstatus}</td>
                                            <td>                                                
                                                {trx?.paid_date}
                                            </td>
                                            <td>
                                                {trx?.transaction_date} {trx?.transaction_time}
                                            </td>



                                        </tr>
                                    })}
                                    {/* <tr>
                                        <td><strong>CodeGo SRL</strong></td>
                                        <td>CodeGo SRL(MILAN)</td>
                                        <td>
                                            PKBFJO
                                        </td>
                                        <td>€1.0000000</td>
                                        <td>
                                            unpaid
                                        </td>
                                        <td>
                                            04 jan 2024 00:00
                                        </td>
                                        <td>
                                            12 jan 2024 09:07
                                        </td>



                                    </tr> */}



                                </tbody>
                            </table>
                        </div> :

                            <div class="container h-100">
                                <div class="row justify-content-center h-100 align-items-center">
                                    <div class="col-md-7">
                                        <div class="form-input-content text-center error-page">
                                            {/* <h1 class="error-text fw-bold">OOPS</h1> */}
                                            <h5><i class="fa fa-exclamation-triangle text-warning"></i>No Transactions found</h5>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div> :
                        <div className="text-center my-5 ">
                            <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                </div>
                {!loading && <div className="text-end float-right d-flex justify-content-end">
                    <Pagination
                        totalPages={Math.ceil(pagination.num_rows / pagination.per_page)}
                        currentPage={pagination.page_number}
                        onPageChange={getTransactions}
                    />

                </div>}



            </div>

        </>
    )
}

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const maxPagesToShow = 4;

    const calculatePageNumbers = () => {
        const pageNumbers = [];

        // Calculate the starting and ending page numbers to display
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        // Adjust if the ending page number is less than the max pages to show
        if (endPage < maxPagesToShow) {
            endPage = Math.min(maxPagesToShow, totalPages);
        }

        // Add page numbers to the array
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const renderPagination = () => {
        const pageNumbers = calculatePageNumbers();

        return (
            <ul className="pagination pagination-gutter pagination-primary no-bg">
                {/* Previous Page */}
                <li className={`page-item page-indicator ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="la la-angle-left"></i>
                    </button>
                </li>

                {/* Page Numbers */}
                {pageNumbers.map((page) => (
                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(page)}>
                            {page}
                        </button>
                    </li>
                ))}

                {/* Dots */}
                {totalPages > maxPagesToShow && currentPage < totalPages - maxPagesToShow + 1 && (
                    <li className="page-item disabled">
                        <span className="page-link">...</span>
                    </li>
                )}

                {/* Next Page */}
                <li className={`page-item page-indicator ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="la la-angle-right"></i>
                    </button>
                </li>
            </ul>
        );
    };

    return <nav>{renderPagination()}</nav>;
};
export default POSTransactionsHistory