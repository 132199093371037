const baseUrl = "http://localhost:3001"
const liveUrl = "https://apifront-coxi.codegotech.com"
export const LOCAL_BACKEND_URL_GENERAL = `${liveUrl}/api/callBackend`
export const LOCAL_BACKEND_URL_BASE = `${liveUrl}/api`
export const AUTH_LOCAL_BACKEND_URL_BASE = `${liveUrl}/api/authRoutes`
export const AUTH_LOCAL_BACKEND_URL_BASE_FORM = `${liveUrl}/api/authRoutesForm`
export const LOCAL_FORGET_PASSWORD = `${liveUrl}/api/forgetPassword`
export const LOCAL_FORGET_WEB_VERIFY = `${liveUrl}/api/webforgotverify`
export const LOCAL_RESET_PASSWORD = `${liveUrl}/api/reset-password`
export const VERIFY_EMAIL_OTP_ACCOUNT_OPEN = `${liveUrl}/api/verify-email-otp`

export const API_ENDPOINTS = {
    OPEN_ACCOUNT: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('openAccount')}`,
    VERIFY_OTP: VERIFY_EMAIL_OTP_ACCOUNT_OPEN,
    GET_COUNTRY_LIST: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getCountriesList')}`,
    GET_PHONE_CODE: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getPhoneCode')}`,
    GET_INDUSTRIES: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getindustries')}`,
    GET_COMPANY_SIZE_LIST: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getCompanySizesList')}`,
    UPDATE_COMPANY_DETAILS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('updatecompanydetails')}`,
    UPLOAD_COMPANY_DOCUMENTS: `${AUTH_LOCAL_BACKEND_URL_BASE_FORM}?url=${encodeURIComponent('uploadCompanyDocument')}`,
    LOGIN: `${LOCAL_BACKEND_URL_BASE}/login`,
    LOGIN_VERIFY: `${LOCAL_BACKEND_URL_BASE}/verifyLogin`,
    GET_COMPANY_STATUS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getCompanyStatus')}`,
    GET_DIRECTOR_ROLE: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getRole')}`,
    ADD_COMPANY_DIRECTOR: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('addcompanydirector')}`,
    RESEND_KYC_LINK: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('sendkyclink')}`,
    PROFILE_DETAILS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('profile_details')}`,
    GET_COMPANY_DOCUMENTS_STATUS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getCompanyDocumentStatus')}`,
    GET_DASHBOARD_DETAILS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('dashboard')}`,
    SHARE_IBAN: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('shareiban')}`,
    CREATE_IBAN: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('createIban')}`,
    ADD_BENEFICIARY: `${AUTH_LOCAL_BACKEND_URL_BASE_FORM}?url=${encodeURIComponent('addbeneficary')}`,
    DELETE_BENEFICIARY: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('beneficiaries_delete')}`,
    BENEFICIARIES_LIST: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('beneficiaries_list')}`,
    IBAN_LIST: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_list')}`,
    TRANSACTIONS_LIST: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_transaction')}`,
    TRANSACTION_DETAILS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_transaction_detail')}`,
    SEND_MONEY: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_sendmoney')}`,
    CONFIRM_IBAN_OTP: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_confirmotp')}`,
    GET_SELECTED_PLAN: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getSelectPlan')}`,
    GET_NOTIFICATIONS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('notification')}`,
    GET_CONFIG_SETTINGS: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('configSetting')}`,
    GET_DEBIT_CARDS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('debitcardList')}`,
    WEB_VERIFY_LOGIN: `${LOCAL_BACKEND_URL_BASE}/webVerifylogin`,
    GET_PRICING_PLANS: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getPlan')}`,
    CRYPTO_WALLET: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('crypto_wallet')}`,
    GET_ALL_WALLETS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getAllwallet')}`,
    GET_TRX_COIN: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getTrxcoin')}`,
    GET_CRYPTO_LATEST_TRANSACTIONS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getTrxlastest')}`,
    GET_POS_TRANSACTIONS: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getallpostrx')}`,
    IBAN_UPLOAD_TRX_DOC: `${AUTH_LOCAL_BACKEND_URL_BASE_FORM}?url=${encodeURIComponent('iban_uploadtrxdoc')}`,
    POS_PAYMENT_HISTORY: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('iban_pospayment')}`,
    DOWNLOAD_INVOICE: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('downloadinvoice')}`,
    DOWNLOAD_BANK_STATEMENT: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('bankstatement')}`,
    SOURCE_OF_FUND: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getSourcefund')}`,
    ADD_SHARE_HOLDER: `${AUTH_LOCAL_BACKEND_URL_BASE_FORM}?url=${encodeURIComponent('addibancompanyshareholder')}`,
    SHAREHOLDERS_LIST: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('getShareholderList')}`,
    GET_HOME_CONTENT: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('get_page_content')}`,
    GET_TERMS_CONDITIONS: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('get_tc')}`,
    GET_PRIVACY_POLICY: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('get_privacy')}`,
    CONTACT_US: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('contact')}`,
    FORGET_PASSWORD: LOCAL_FORGET_PASSWORD,
    WEB_FORGOT_VERIFY: LOCAL_FORGET_WEB_VERIFY,
    RESET_PASSWORD: LOCAL_RESET_PASSWORD,
    CAPTCHA_VERIFICATION: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('verifygooglecaptcha')}`,
    LOGOUT: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('logout')}`,
    UPDATE_USER_LIVE: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('updateUserLive')}`,
    POS_PLAN: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('posPlan')}`,
    ACCEPT_DECLINE_PLAN: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('ibanacceptrejectplan')}`,
	RESEND_PUSH:`${LOCAL_BACKEND_URL_BASE}/resend-push`,
    UPDATE_PLAN: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('pos_plan_uupdate')}`,
    GET_META_CONTENT: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('get_meta_content')}`,
	APPROVE_DECLINE_LOGIN: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('acceptRejectLogin')}`,
	UPDATE_NOTIFICATION: `${AUTH_LOCAL_BACKEND_URL_BASE}?url=${encodeURIComponent('update_notification')}`,
	GET_COMPANY_TYPE_LIST: `${LOCAL_BACKEND_URL_GENERAL}?url=${encodeURIComponent('getCompanyLegalFormsList')}`,
}
export const API_METHODS = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
}

