import { useEffect, useState } from "react";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Footer from "./footerBusiness";
import { Button, Drawer, Radio, Space } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints";
import { whitelabel_id } from "../../../constants/ids";
import apiCall from "../../../utils/apiCall";
import { useTheme } from "../../Theme/themeContext";
const BusinessNavbar = ({ children }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const [logo, setLogo] = useState('')
    const [darkLogo, setDarkLogo] = useState('')
    const [configSettings, setConfigSettings] = useState('')
    const [personalLinks, setPersonalLinks] = useState({})
    const { lightTheme, toggleTheme } = useTheme();
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",
        ];

        const loadScript = (url) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = url;
                script.async = true;
                script.onload = resolve;
                script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
                document.body.appendChild(script);
            });
        };

        const loadScripts = async () => {
            try {
                await Promise.all(scriptUrls.map((url) => loadScript(url)));
                console.log("All scripts loaded successfully");
            } catch (error) {
                console.error(error.message);
                // You can handle the error here, for example, by displaying an error message to the user.
            }
        };

        loadScripts();

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);

    useEffect(() => {
        getConfigSettings()
    }, [])
    const getConfigSettings = async () => {

        const url = API_ENDPOINTS.GET_CONFIG_SETTINGS
        const body = {
            whitelabel_id: whitelabel_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
            const data = response?.config
            setConfigSettings(response?.config)
            setLogo(data?.logo)
            setDarkLogo(data?.dark_logo)
            setPersonalLinks(response?.links)

        } else if (response?.status == 0) {
            console.error(response?.message)


        } else {
            console.error('Unable to fetch.')
        }
    }

    const handleRedirect = (link) => {
        window.location.href = link;
        window.location.reload();
        // navigate(`/${link}`)
    };
    const imageStyle = {
        maxWidth: '170px',
    };
    const imageStyle2 = {
        maxWidth: '100px',
    };
    //   if (lightTheme) {
    //     imageStyle.filter = 'invert(80%)';
    //     imageStyle2.filter = 'invert(80%)'; 
    //   }
    return (
        <div>

            {/* id="main-wrapper" */}
            <div id="main-wrapper" style={{}}>

                <div className="header" style={{ paddingLeft: '5%', width: '100%', }}>
                    <div className="header-content" style={{ marginRight: "10%" }}>
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse justify-content-between">
                                <div className="header-left">
                                    <div className="dashboard_bar"> <img src={lightTheme ? logo : darkLogo} className="text-black" alt=""
                                        style={imageStyle}
                                    /></div>
                                </div>
                                <div className="header-left">
                                    <div className="d-block d-sm-none me-4"> <img src={lightTheme ? logo : darkLogo} alt="" className="me-auto"
                                        style={imageStyle2}
                                    /></div>
                                </div>
                                <div className="header-left d-none">
                                    <div className="dashboard_bar"> <img src={lightTheme ? logo : darkLogo} alt="" style={{ maxWidth: "170px" }} /></div>
                                </div>
                                <ul className="navbar-nav header-right  d-none d-lg-flex">
                                    <li className="nav-item d-flex align-items-end">
                                        <Link to={"/"}>  <a href="javascript:void(0)">  <h4>HOME</h4></a></Link>
                                    </li>
                                    {personalLinks?.personal_website_url != '' && <li className="nav-item d-flex align-items-end">
                                        <a href={personalLinks?.personal_website_url} rel="noopener noreferrer"> <h4>PERSONAL</h4></a>
                                    </li>}
                                    <li className="nav-item d-flex align-items-end">
                                        <Link to={"/pricing"}>   <a href="javascript:void(0)">  <h4>PRICING</h4></a></Link>
                                    </li>
                                    {configSettings?.support_link && <li className="nav-item d-flex align-items-end">
                                        <a href={configSettings?.support_link} rel="noopener noreferrer">
                                            <h4>SUPPORT</h4>
                                        </a>

                                    </li>}
                                    {/* <li className="nav-item d-flex align-items-end">
                                        <a href="https://codegotech.com/" rel="noopener noreferrer">
                                            <h4>CODEGOTECH</h4>
                                        </a>
                                    </li> */}
                                </ul>
                                <div className="dropdown header-profile2 ">
                                    <ul className="navbar-nav header-right me-sm-4">

                                        <li className="nav-item dropdown notification_dropdown ">
                                            <a onClick={toggleTheme}
                                                className="nav-link bell-link "
                                                href="javascript:void(0);"
                                            >
                                                {!lightTheme ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sun" viewBox="0 0 16 16">
                                                    <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon" viewBox="0 0 16 16">
                                                    <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286" />
                                                </svg>}
                                            </a>
                                        </li>
                                        <li>
                                            <Link to={"/login"}> <button type="button" class="btn light btn-primary me-2 d-none d-md-inline-block">LOGIN</button></Link>
                                        </li>
                                        <li>
                                            <Link to={"/open-account"}> <button type="button" class="btn light btn-primary d-flex d-none d-md-inline-block">OPEN ACCOUNT</button></Link>

                                        </li>
                                        <li className="nav-item dropdown notification_dropdown d-flex d-lg-none">
                                            <a onClick={showDrawer}
                                                className="nav-link bell-link "
                                                href="javascript:void(0);"
                                            >
                                                {/* <svg
                                                    width={28}
                                                    height={28}
                                                    viewBox="0 0 28 28"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        opacity="0.3"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.00001 2.33331H21C21.6443 2.33331 22.1667 2.85565 22.1667 3.49998V15.1666C22.1667 15.811 21.6443 16.3333 21 16.3333H7.00001C6.35568 16.3333 5.83334 15.811 5.83334 15.1666V3.49998C5.83334 2.85565 6.35568 2.33331 7.00001 2.33331ZM16.1 4.66665C15.3489 4.66665 14.4705 5.51814 14 6.06665C13.5295 5.51814 12.6511 4.66665 11.9 4.66665C10.5705 4.66665 9.80001 5.70366 9.80001 7.0235C9.80001 8.4856 11.2 10.0333 14 11.6666C16.8 10.0333 18.2 8.51665 18.2 7.11665C18.2 5.79681 17.4295 4.66665 16.1 4.66665Z"
                                                        fill="#B9A8FF"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M4.42487 7.66798L14 14.5833L23.5751 7.66798C23.8363 7.47935 24.2009 7.53816 24.3896 7.79933C24.4614 7.89874 24.5 8.01825 24.5 8.14087V19.8333C24.5 21.122 23.4553 22.1667 22.1667 22.1667H5.83333C4.54467 22.1667 3.5 21.122 3.5 19.8333V8.14087C3.5 7.81871 3.76117 7.55754 4.08333 7.55754C4.20596 7.55754 4.32546 7.59618 4.42487 7.66798Z"
                                                        fill="#B9A8FF"
                                                    />
                                                </svg> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                                                </svg>

                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* <div className="fixed-top d-flex align-items-center" style={{ minHeight: '100px', backgroundColor: '#311898', zIndex:50 }}>
                    <div className="d-flex justify-content-between align-items-center cardsContainer mx-auto " >
                        <div>
                            <img src="https://res.cloudinary.com/hashfort/image/upload/v1682595042/logo/ictgvexi7nnzfe7sweff.png" alt="" style={{maxWidth:"170px"}}/>
                        </div>
                        <div>
                            <ul className="  d-none d-lg-flex gap-2 ">
                                <li className="nav-item d-flex align-items-end">
                                    <a href={"/business"}>  <h4>HOME</h4></a>
                                </li>
                                <li className="nav-item d-flex align-items-end">
                                    <h4>PERSONAL</h4>
                                </li>
                                <li className="nav-item d-flex align-items-end">
                                    <a href={"/pricing"}>  <h4>PRICING</h4></a>
                                </li>
                                <li className="nav-item d-flex align-items-end">
                                    <h4>SUPPORT</h4>
                                </li>
                                <li className="nav-item d-flex align-items-end">
                                    <h4>CODEGOTECH</h4>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <button type="button" class="btn light btn-primary me-2">LOGIN</button>
                            <button type="button" class="btn light btn-primary d-flex">OPEN ACCOUNT</button>
                            <a onClick={showDrawer}
                                className="nav-link bell-link d-flex d-lg-none"
                                href="javascript:void(0);"
                            >
                                <svg
                                    width={28}
                                    height={28}
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.3"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.00001 2.33331H21C21.6443 2.33331 22.1667 2.85565 22.1667 3.49998V15.1666C22.1667 15.811 21.6443 16.3333 21 16.3333H7.00001C6.35568 16.3333 5.83334 15.811 5.83334 15.1666V3.49998C5.83334 2.85565 6.35568 2.33331 7.00001 2.33331ZM16.1 4.66665C15.3489 4.66665 14.4705 5.51814 14 6.06665C13.5295 5.51814 12.6511 4.66665 11.9 4.66665C10.5705 4.66665 9.80001 5.70366 9.80001 7.0235C9.80001 8.4856 11.2 10.0333 14 11.6666C16.8 10.0333 18.2 8.51665 18.2 7.11665C18.2 5.79681 17.4295 4.66665 16.1 4.66665Z"
                                        fill="#B9A8FF"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.42487 7.66798L14 14.5833L23.5751 7.66798C23.8363 7.47935 24.2009 7.53816 24.3896 7.79933C24.4614 7.89874 24.5 8.01825 24.5 8.14087V19.8333C24.5 21.122 23.4553 22.1667 22.1667 22.1667H5.83333C4.54467 22.1667 3.5 21.122 3.5 19.8333V8.14087C3.5 7.81871 3.76117 7.55754 4.08333 7.55754C4.20596 7.55754 4.32546 7.59618 4.42487 7.66798Z"
                                        fill="#B9A8FF"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div> */}

                <Drawer
                    className="custom-drawer"
                    placement={placement}

                    onClose={onClose}
                    open={open}
                    style={{ minWidth: '800px', backgroundColor: '#151718', width: '80% !important', zIndex: 51, color: 'white' }}
                    closable={false}
                >

                    <div style={{ paddingRight: '5%', paddingLeft: '5%' }}>
                        <div className="d-flex justify-between align-items-center  mb-4">
                            <div>
                                <img src={darkLogo} alt="" style={{ maxWidth: "120px" }} />
                            </div>
                            <div style={{ marginLeft: '100px' }}>
                                <i class="bi bi-x text-muted" style={{ fontSize: '30px' }} onClick={onClose}></i>
                            </div>
                        </div>
                        <div>
                            <Link to={"/"}> <h3 className=' ps-2  fs-3 text-white' style={{ fontWeight: "bold" }}>HOME</h3></Link>
                            <div className="border-bottom my-4"></div>
                        </div >
                        {personalLinks?.personal_website_url != '' && <div>
                            <h3 className=' p-2  fs-3 text-white' style={{ fontWeight: "bold" }}>PERSONAL</h3>
                            <div className="border-bottom my-4"></div>
                        </div>}
                        <div>
                            <Link to={"/pricing"}>  <h3 className=' p-2  fs-3 text-white' style={{ fontWeight: "bold" }}>PRICING</h3></Link>
                            <div className="border-bottom my-4"></div>
                        </div>

                        {configSettings?.support_link && <div>
                            <a href={configSettings?.support_link} rel="noopener noreferrer">  <h3 className=' p-2  fs-3 text-white' style={{ fontWeight: "bold" }}>SUPPORT</h3></a>
                            <div className="border-bottom my-4"></div>
                        </div>}

                        <div>
                            <Link to={"/login"}>     <h3 className='p-2  fs-3 text-white' style={{ fontWeight: "bold" }}>LOGIN</h3></Link>
                            <div className="border-bottom my-4"></div>
                        </div>
                        <Link to={"/open-account"}>    <h3 className='p-2  fs-3 text-white' style={{ fontWeight: "bold" }}>OPEN ACCOUNT</h3></Link>
                    </div>
                    <div className='  text-white mx-4 ' style={{ fontWeight: "bold", fontSize: "30px", marginTop: '40px', paddingBottom: "15px", }}>Get In Touch</div>
                    <div className='d-flex m-4'>
                        <span className='badge' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '60px', height: '60px', borderRadius: '100%', background: '#141774' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                        </span>
                        <div className='mx-4'>
                            <div style={{ fontWeight: 400, fontSize: "16px", color: "white" }}>Email</div>
                            <div style={{ fontWeight: 'bold', color: "#bdbeec", fontSize: '16px' }}>info@coxiwallet.com</div>
                        </div>
                    </div>
                    
                    <div className='d-flex m-4'>
                        <span className='badge' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', borderRadius: '100%', background: '#141774' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>

                        </span>
                        <div className='mx-4'>
                            <div style={{ fontWeight: 400, fontSize: "16px", color: "white" }}>Location</div>
                            <div style={{ fontWeight: 'bold', color: "#bdbeec" }}>Plovdiv, 4000, Stefan Verkovich str. 10, Bulgaria</div>
                        </div>
                    </div>
                    <div className='border-top  '>
                        <div className='d-flex mt-4'>
                            <span className='mx-1 rounded' style={{ color: "blue", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', background: '#FFFFFF' }}>
                                <i class="fab fa-twitter"></i>
                            </span>
                            <span className='badge mx-1' style={{ display: 'flex', color: "black", alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', background: '#FFFFFF' }}>
                                <i class="fab fa-instagram"></i>
                            </span>
                            <span className='rounded mx-1' style={{ display: 'flex', color: "black", alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', background: '#FFFFFF' }}>
                                <i class="fab fa-dribbble"></i>
                            </span>
                            <span className='rounded mx-1' style={{ display: 'flex', color: "blue", alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', background: '#FFFFFF' }}>
                                <i class="fab fa-facebook-square"></i>
                            </span>
                        </div>
                    </div>
                </Drawer>
                <div >
                    {children}
                </div>
            </div>
        </div>
    )
}
export default BusinessNavbar;
